import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          17.04.2017 - The day when <span className="purple">eNGaNe_tEAm </span> was created as a local group chat, but after the sudden closure of both <span className="purple">Kontora Gamerov</span> and <span className="purple">Novaya Kontora</span>, <span className="purple">eNGaNe_tEAm</span> has became public, allowing most people from the previous chats to migrate to the new one.
          </p>

          {/* <p style={{ textAlign: "justify" }}>
          17.04.2017 - The day when <span className="purple">eNGaNe_tEAm </span> was created as a local group chat, but after the sudden closure of both <span className="purple">Gamers Office</span> (AKA <span className="purple">Kontora Gamerov</span>) and <span className="purple">New Office</span> (AKA <span className="purple">Novaya Kontora</span>), <span className="purple">eNGaNe_tEAm</span> has became public, allowing most people from the previous chats to migrate to the new one.
          </p> */}

          <p style={{ textAlign: "justify" }}>
          1.08.2017 - First Discord server was created as an extension for already existing group chat. (It's now called <span className="purple">WpiZDu</span>, but the server currently lost its purpose and serves as a legacy). 
          </p>
          <p style={{ textAlign: "justify" }}>
          13.05.2019 - We migrated to a newly created Discord server named <span className="purple">ETFF</span>. This server shifted its primary focus from being merely a group chat to a somewhat more complex and serious endeavor..
          </p>
          <p style={{ textAlign: "justify" }}>
          Somewhere between 2020 and 2021, the first projects, such as Kanade's predecessor, began to emerge as <span className="purple">eNGaNe</span> was renamed to <span className="purple">Exelus</span>, marking the start of its journey towards becoming a legitimate organization.. 
          </p>
          <p style={{ textAlign: "justify" }}>
          But there is still a long way to go...
          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
          From Finality, the Origin {" "}
          </p>
          {/* <footer className="blockquote-footer">NIERAI</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
