import React, { useState, useEffect } from 'react';
import Type from "./Type";

function HideType() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ padding: 48, textAlign: "left" }}>
      {isMobile ? null : <Type />}
    </div>
  );
}

export default HideType;