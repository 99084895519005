import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Type from "./Type";

// function Discord() {
//     useEffect(() => {
//       const timeout = setTimeout(() => {
//         // 👇️ redirects to an external URL
//         window.location.replace('https://discord.gg/3HwV9yvnyH');
//       }, 3000);
  
//       return () => clearTimeout(timeout);
//     }, []);
  
//     return <>Will redirect in 3 seconds...</>;
//   }
  


function Discord() {
    useEffect(() => {
        const timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace('https://discord.gg/3HwV9yvnyH');
        }, 3000);
    
        return () => clearTimeout(timeout);
      }, []);
  return (
    <section>
    <Container fluid className="discord-section" style={{ height: '100vh' }}>
    <Particle />

      <Container>
      <Row></Row>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            REDIRECTING YOU TO <span className="purple"> DISCORD</span>
            </h1>
            <p className="home-about-body">
            Please stand <b className="purple">still</b> - this won't take long.
            <br />
            <br />
            I promise.
            </p>
            <br />
            <br />
            <br />
            <div style={{ textAlign: "center"}}><Type /></div>
          </Col>

        </Row>
    </Container>
    </Container>
    </section>
  );
}

export default Discord;