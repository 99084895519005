import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          // "Full-Stack Developer.",
          "3",
          "2",
          "1",
          "Warp!",
        ],
        autoStart: true,
        loop: false,
        delay: 10,
        deleteSpeed: 10,
        pauseFor: 900,
      }}
    />
  );
}

export default Type;
