import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaDiscord, FaFileAlt } from "react-icons/fa";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      {props.imgPath && (
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      )}
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
        {props.description}
        </Card.Text>
        {props.dsLink && (
        <Button style={{ borderRadius: "40px", marginTop: "20px" }} variant="primary" href={props.dsLink} target="_blank">
          <FaDiscord /> &nbsp; Add App
        </Button>
        )}
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link, it will render the below component */}

        {props.prLink && (
          <Button
            variant="primary"
            href={props.prLink}
            target="_blank"
            style={{ marginLeft: "10px", borderRadius: "40px", marginTop: "20px" }}
          >
            <FaFileAlt /> &nbsp;
            {"Privacy"}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectCards;
