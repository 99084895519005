import React from "react";
import Card from "react-bootstrap/Card";
function PrivacyCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Confidentiality
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)" }}>
          This Privacy Policy outlines how we and our related services collect, use, and protect your personal information when you interact with our products.
          </p>

          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Data Collection
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)" }}>
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>1. Server Settings:</span> We store settings related to individual servers where our bots are active. This data remains in our database even after the bot leaves a server. To request the deletion of your server data, please contact our support team.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>2. Command Logs:</span> We record the time, username, user ID, server name, and server ID for each command entered and certain other interactions with our bots. This data is retained for approximately one month.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>3.  User Interactions:</span> We do not collect or store messages that are not commands or bot interactions.
          </p>

          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Data Usage
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)"}}>
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>1. Service Delivery:</span> We use collected data to provide our bots services, such as responding to commands and maintaining server settings.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>2. Security and Improvement:</span> We may analyze data to identify and address security vulnerabilities, improve our bots functionality, and enhance user experience.
          </p>

          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Data Storage and Security
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)"}}>
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>1. Retention:</span> We retain collected information for as long as necessary to fulfill the purposes outlined in this policy or as required by law.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>2. Protection:</span> We implement commercially reasonable security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. 
          {/* These measures include encryption, access controls, and regular security reviews.    */}

          </p>
          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Third-Party Sharing
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)"}}>
          We do not share your personal information with any third parties, except when interacting with AI modules. Every time an AI module is used, we send data such as the time, your username, and the text you've written to the AI module provider.
          </p>

          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Contact
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)"}}>
          If you have any questions or concerns regarding this Privacy Policy, you can contact us in the <a style={{color: "rgb(199, 112, 240)"}} href="https://discord.gg/3HwV9yvnyH">Support Server</a>, or you can contact @nierai on Discord.
          </p>

          <p style={{ textAlign: "center", fontSize: "1.2em", paddingTop: "15px" }}>
          Additional Considerations
          </p>

          <p style={{ textAlign: "justify", fontSize: "0.9em", color: "rgb(220, 220, 220)"}}>
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>1. Consent:</span> By using our bots, you consent to the collection and processing of your data as described in this policy.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>2. Changes:</span> We may update this Privacy Policy from time to time. We will notify you of any significant changes through our bots or other appropriate means.
          <br /><br />
          <span style={{ fontSize: "1.1em", color: "rgb(255, 255, 255)"}}>3. Applicable Laws:</span> Our data practices are subject to applicable data protection laws and regulations.
</p>

          {/* <p style={{ color: "rgb(155 126 172)" }}>
          NIERAI
          </p> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default PrivacyCard;
