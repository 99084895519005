import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
// import Converter from "../../Assets/Projects/project1.png";
// import Manager from "../../Assets/Projects/project2.png";
import Kana from "../../Assets/Projects/Kanadski.png";
import Lina from "../../Assets/Projects/Linski.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        Somewhat serious <strong className="purple">projects </strong> we made
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Kana}
              title="'39'Kanade!"
              description="Advanced discord music bot that supports Youtube, Soundcloud, Vk and Yandex Music. Also includes memes, local playlists and AI ChatBot."
              dsLink="https://discord.com/oauth2/authorize?client_id=830442608291217439"
              prLink="/projects/privacy-policy"
            />
          </Col>

          <Col md={5} className="project-card">
            <ProjectCard
              imgPath={Lina}
              title="Lina"
              description="Convenient Discord translator bot that can be accessed through the context menu (RMB). Works in DMs, group chats, and all servers (unless admins have restricted external apps). Developed by @rainy351."
              dsLink="https://discord.com/oauth2/authorize?client_id=1236880537855987805"
              prLink=""
            />
          </Col>
          
          {/* Section No. 2 */}
          {/* <h1 className="project-heading">
          Planned <strong className="purple">things </strong>
        </h1>
        <Col md={5} className="project-card">
            <ProjectCard
              title="GMTB"
              description="A multipurpose bot. This should be a collab, so if you are interesting, contact @nierai on Discord"
            />
          </Col>
          <Col md={5} className="project-card">
            <ProjectCard
              title="KODOIFI Project"
              description=""
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
    
  );
}

export default Projects;
