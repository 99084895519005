import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaDiscord, FaTelegram } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            What is <span className="purple">EXELUS</span>?
            </h1>
            <p className="home-about-body">
            It's a reimagined <b className="purple">all-in-one</b> place for spreading your <b className="purple">interests</b> and <b className="purple">projects</b>. Play, discuss, share, create, collaborate.
              <br />
              <br />
              Absolutely <b className="purple">@everyone</b> is welcome to join&nbsp;
                <b className="purple">us</b>, even if you are not a{" "}
                <b className="purple">
                developer, artist, producer, streamer or gamer. 
                </b> <br />(The only requirment is to have common sense).
              <br />
              <br />It's time to start your new <b className="purple">journey</b>.
              Don't miss this
                <b className="purple"> opportunity</b>.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>Join us anytime</h1>
            <p>
              We would love to see <span className="purple">you </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://discord.gg/3HwV9yvnyH"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaDiscord />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://t.me/ETFFLAND"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaTelegram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
