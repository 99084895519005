import React from "react";
import Typewri from "typewriter-effect";

function Type() {
  return (
    <Typewri
      options={{
        strings: [
          // "Full-Stack Developer.",
          "Discord Server.",
          "Meme Group.",
          "Shitpost Shelter.",
          "Self-Proclaimed Organization.",
          "The KRAI Of The World.",
          "Dev Studio.",
          "Talents Union.",
          "Gamer Nexus.",
          "Croisant?",
          `...`,
          "[Insert any other cliche text here].",
          "Gomen Yuuka..."
        ],
        autoStart: true,
        loop: true,
        delay: 120,
        deleteSpeed: 60,
      }}
    />
  );
}

export default Type;
